import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';

const bugsnag = Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
  releaseStage: import.meta.env.VITE_STAGE,
  enabledReleaseStages: ['production', 'staging', 'local'],
  trackInlineScripts: true,
  maxBreadcrumbs: 5,
  maxEvents: 100,
  user: {
    id: window?.Shopify?.customer?.id || null,
    email: window?.Shopify?.customer?.email || null,
    name: window?.Shopify?.customer?.name || null,
  },
  metadata: {
    theme: {
      id: window?.Shopify?.theme?.id || null,
      name: window?.Shopify?.theme?.name || null,
    },
  },
  onError(event) {
    event?.addMetadata('customer', {
      id: window?.Shopify?.customer?.id || null,
      email: window?.Shopify?.customer?.email || null,
      name: window?.Shopify?.customer?.name || null,
    });
  },
});

BugsnagPerformance.start({
  apiKey: '81356bbefff6e89ac3ebf255a5b2d00a',
});

window.Bugsnag = bugsnag;

export default bugsnag;
