import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('productCarousel', () => ({

    async init() {
      if (!this.$refs.slider) {
        return;
      }

      const slider = new BlazeSlider(this.$refs.slider, {
        all: {
          loop: false,
          draggable: true,
          slidesToShow: 1.25,
          slideGap: '16px'
        },
        '(min-width: 550px)': {
          slidesToShow: 1.75,
        },
        '(min-width: 1024px)': {
          slidesToShow: 3,
          slideGap: '32px'
        },
      })
    },

    async initSlider(index: number) {
      const { default: BlazeSlider } = await import("blaze-slider");

      const slider = new BlazeSlider(this.$refs[`slider-${index}`], {
        all: {
          loop: false,
          slidesToShow: 1.25,
          slideGap: '16px'
        },
        '(min-width: 550px)': {
          slidesToShow: 1.75,
        },
        '(min-width: 768px)': {
          slidesToShow: 2.5,
          slideGap: '32px'
        },
        '(min-width: 1440px)': {
          slidesToShow: 3,
        },
      })

      slider.onSlide((_, first, last) => {
        this.prevDisabled = first === 0;
        this.nextDisabled = slider.totalSlides <= (last + 1);
      })
    },
    async setActiveBlock(index: number) {
      this.activeBlock = index;

      await this.initSlider(index);
    },
    next() {
      if (!this.activeSlider) {
        return;
      }

      this.activeSlider.next();
    },
    prev() {
      if (!this.activeSlider) {
        return;
      }

      this.activeSlider.prev();
    }
  }));
};
