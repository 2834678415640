import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('productFeature', () => ({

    async init() {
      const { default: BlazeSlider } = await import("blaze-slider");

      const slider = new BlazeSlider(this.$refs.blazeSlider, {
        all: {
          loop: true,
          slidesToShow: 3,
        },
        '(min-width: 640px)': {
          slidesToShow: 5,
        }
      })
    }
  }));
};