import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('cartProductRecommendationItem', () => ({
    get variant() {
      if (this.item.variants.length) {
        return this.item.variants[0];
      } else {
        return null;
      }
    },
    get discount() {
      const itemData = this.variant || this.item;
      if (itemData.compare_at_price > 0 && itemData.compare_at_price > itemData.price) {
        return `${Math.round(100 - (itemData.price / itemData.compare_at_price) * 100)}% off`;
      } else {
        return '';
      }
    },
    get price() {
      return `$${(this.variant || this.item).price / 100}`
    },
    get variantTitle() {
      return this.variant?.title && this.variant.title !== "Default Title" ? this.variant.title : "";
    },

    async addToCart() {
      const properties = {} as { [key: string]: any };

      if (this.item.variants?.length > 1) {
        properties['_variants'] = JSON.stringify(this.item.variants.map((item: any) => ({ id: item.id, title: item.title })));
      }

      await this.$store.cart.addItem({
        quantity: 1,
        id: this.variant?.id || this.item.id,
        properties
      }, (this.variant || this.item).price);
    }
  }) as any);
};
