import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('header', (props: any) => ({
    showAnnouncementBar: true,
    isShopOpen: false,
    isAboutOpen: false,
    showHeader: true,

    init() {
      if (props.hideHeader) {
        document.addEventListener("wheel", (e) => {
          if (e.deltaY > 0) {
            this.showHeader = false;
          } else if (e.deltaY < 0) {
            this.showHeader = true;
          }
        });
      }
    },
    openShop() {
      this.isAboutOpen = false;
      this.isShopOpen = true;
      document.getElementsByTagName('html')[0].classList.add("is-shop-menu-visible");
    },
    openAbout() {
      this.isShopOpen = false;
      this.isAboutOpen = true;
    },
    closeMegamenu() {
      this.isShopOpen = false;
      this.isAboutOpen = false;
      document.getElementsByTagName('html')[0].classList.remove("is-shop-menu-visible");
    },
    closeAnnouncementBar() {
      const headerPlaceholder = document.querySelector<HTMLElement>('.header-placeholder');

      if (headerPlaceholder) {
        headerPlaceholder.style.height = `${headerPlaceholder.offsetHeight - 45}px`;
      }

      this.showAnnouncementBar = false;
      const header = document.querySelector('header.header  .header__content');
      if (header != null) {
        setTimeout(() => {
          document.body.style.setProperty('--lsg-header-height', `${header.offsetHeight}px`);
        }, 10);
      }
     }
  }));
};
