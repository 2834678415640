import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('newsletterSignup', (props: any) => ({
    eyebrow: '',
    headline: '',
    listId: '',
    source: '',
    ...props,
    email: '',
    inlineError: false,
    error: false,
    submitted: false,

    async subscribeToBlog(listId: string, email: string, source?: string) {
      const options = {
        method: 'POST',
        headers: {
          revision: '2023-01-01',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              list_id: listId,
              email,
              ...(source ? { custom_source: source } : {}),
            },
          },
        }),
      };

      try {
        const response = await fetch(
          `https://a.klaviyo.com/client/subscriptions/?company_id=${this.$store.env.KLAVIYO_COMPANY_ID}`,
          options
        );

        return response;
      } catch (err: any) {
        console.error(err);
      }
    },
    async subscribe() {
      const response = await this.subscribeToBlog(
        this.listId,
        this.email,
        this.source
      );
      if (response?.ok) {
        this.email = '';
        this.inlineError = false;
        this.error = false;
        this.submitted = true;
        // todo
        // trackUserSignup({ method: 'email_subscription', email });
      } else {
        this.error = true;
      }
    },
    validateEmail() {
      const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

      if (!this.email || !regex.test(this.email)) {
        this.inlineError = true;
      } else {
        this.inlineError = false;
        this.subscribe();
      }
    },
    handleKeyUp(e: any) {
      e.preventDefault();
      if (e.key === 'Enter') {
        this.validateEmail();
      }
    },
    resetState() {
      this.email = '';
      this.inlineError = false;
      this.error = false;
      this.submitted = false;
    },
  }));
};
