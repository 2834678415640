import { Alpine as AlpineType } from 'alpinejs';
import { formatPrice } from '@/utils';

export default (Alpine: AlpineType) => {
  Alpine.data('cartDrawer', () => ({
    get subtotalPrice() {
      return formatPrice(this.$store.cart.data?.items_subtotal_price || 0);
    },
    get isThresholdPassed() {
      return this.$store.cart.data?.items_subtotal_price >= (this.$store.freeGift.thresholdInCents);
    },
    get threshold() {
      return formatPrice(this.$store.freeGift.thresholdInCents, "${{amount_no_decimals}}");
    },
    get amountToPassThreshold() {
      return formatPrice(this.$store.freeGift.thresholdInCents - this.$store.cart.data?.items_subtotal_price, "${{amount_no_decimals}}");
    },
    get progressBarStyle() {
      return {
        width: `${Math.min((this.$store.cart.data?.items_subtotal_price / this.$store.freeGift.thresholdInCents) * 100, 100)}%`
      };
    }
  } as any));
};
