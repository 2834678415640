import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('videoPlayer', videoId => ({
    isPlaying: false,
    embedUrl: `https://www.youtube.com/embed/${videoId}`,
    iframeParam: '?autoplay=1',
    videoSrc: '',

    get videoUrl() {
      return `${this.embedUrl}${this.iframeParam}`;
    },

    play() {
      this.isPlaying = true;

      this.$nextTick(() => {
        this.videoSrc = this.videoUrl;
      });
    },

    stop() {
      this.isPlaying = false;

      this.$nextTick(() => {
        this.videoSrc = '';
      });
    },
  }));
};
