import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('quote', () => ({
    activeQuote: 0,

    setActiveQuote(index: number) {
      this.activeQuote = index;
    }
  }));
};