import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('listCarousel', (props: any) => ({
    ...props,
    activeSlide: 2,
    blazeSlider: null as null | BlazeSlider,

    init() {
      this.mediaQueryListener();
    },

    async initSlider() {
      const { default: BlazeSlider } = await import('blaze-slider');

      this.blazeSlider = new BlazeSlider(this.$refs.blazeSlider, {
        all: {
          loop: true,
          slidesToShow: 1.15,
        },
      });

      this.calculateMobileSlide();

      this.blazeSlider.onSlide((pageIndex: number) => {
        this.setActiveSlide(
          pageIndex + 1 > this.blockLength ? 1 : pageIndex + 1
        );
      });
    },

    setActiveSlide(index: number) {
      this.activeSlide = index;
    },

    mediaQueryListener() {
      const mediaQuery = window.matchMedia('(min-width: 1024px)');

      const isDesktop = (e: any) => {
        if (e.matches) {
          this.activeSlide = Number(this.defaultSlide);
          if (this.blazeSlider) {
            this.blazeSlider.destroy();
          }
        } else {
          if (!this.blazeSlider) {
            this.activeSlide = Number(this.defaultSlide);
            this.initSlider();
          }
        }
      };

      mediaQuery.addEventListener('change', isDesktop);
      isDesktop(mediaQuery);
    },

    calculateMobileSlide() {
      const diff = 1 - this.defaultSlide;

      return diff > 0
        ? this.blazeSlider.prev(diff)
        : diff < 0
          ? this.blazeSlider.next(-diff)
          : false;
    },
  }));
};
