export const storefrontClient = async (query: string, variables: any) => {
  const responce = await fetch('https://foursigmastore-us.myshopify.com/api/2024-01/graphql.json',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': '8c4e583376aa38cf06fe1265ef932506'
      },
      body: JSON.stringify({
        query,
        variables
      })
    }
  )
  const data = await responce.json();
  return data;
}
