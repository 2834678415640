export const updatePage = async (url: string) => {
  const newurl = url;
  const response = await fetch(newurl).then((response) => response.text());

  const html = document.createElement('div');
  html.innerHTML = response;

  const newContent = html.querySelectorAll('[updatable]');

  history.replaceState({}, "", newurl);

  document.body.querySelectorAll('[updatable]').forEach((item, index) => {
    item.innerHTML = newContent[index].innerHTML;
  });
  const urlWithCollections = window?.LSGSetCollections();
  window?.LSGSortByCollection(undefined, undefined);
};
