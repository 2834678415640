export const getProductRecommendations = async ({
  productId,
  // locale = 'en-US',
  intent = 'related',
  limit = 4
}: {
  productId: string,
  // locale?: string,
  intent?: string,
  limit?: number
}) => {
  const responce = await fetch(`/recommendations/products.json?product_id=${productId
    }&limit=${limit}&intent=${intent}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )

  const data = await responce.json();

  if (data?.products?.length) {
    return data.products;
  } else {
    return [];
  }
}
