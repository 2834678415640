import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('accordionGroup', () => ({
    activeAccordion: null,

    setActiveAccordion(id: any) {
      this.activeAccordion = this.activeAccordion == id ? null : id;
    },
  }));
};
