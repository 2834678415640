import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('fullWidthVideo', (props: any) => ({
    ...props,
    iframeVideoTypes: ['youtube', 'vimeo'],
    showCoverImage: true,

    playVideo() {
      this.showCoverImage = false;
      if (this.iframeVideoTypes.includes(this.videoType)) {
        this.$refs.iframe.setAttribute('src', this.getUrl());
      } else {
        this.$refs.player.play();
      }
    },

    getUrl(): string {
      switch (this.videoType) {
        case 'youtube':
          return `https://www.youtube.com/embed/${this.videoCode}?autoplay=1`;
        case 'vimeo':
          return `https://player.vimeo.com/video/${this.videoCode}?autoplay=1`;
        default:
          return '';
      }
    },
  }));
};
