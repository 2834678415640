import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('productCategoryCarousel', () => ({
    activeBlock: 0,
    prevDisabled: true,
    nextDisabled: false,

    async init() {
      await this.initSlider(0);
    },

    get activeSlider(): BlazeSlider | null {
      return (this.$refs[`slider-${this.activeBlock}`] as any)?.blazeSlider;
    },

    async initSlider(index: number) {
      this.prevDisabled = true;
      this.nextDisabled = false;

      if (this.activeSlider) {
        await this.$nextTick();
        this.activeSlider.prev(this.activeSlider.totalSlides);
        return;
      }

      const { default: BlazeSlider } = await import("blaze-slider");

      const slider = new BlazeSlider(this.$refs[`slider-${index}`], {
        all: {
          loop: false,
          slidesToShow: 1.25,
          slideGap: '16px'
        },
        '(min-width: 550px)': {
          slidesToShow: 1.75,
        },
        '(min-width: 768px)': {
          slidesToShow: 2,
        },
        '(min-width: 1024px)': {
          slidesToShow: 2.5,
          slideGap: '32px'
        },
        '(min-width: 1440px)': {
          slidesToShow: 3.5,
        },
      })

      slider.onSlide((_, first, last) => {
        this.prevDisabled = first === 0;
        this.nextDisabled = slider.totalSlides <= (last + 1);
      })
    },
    async setActiveBlock(index: number) {
      this.activeBlock = index;

      await this.initSlider(index);
    },
    next() {
      if (!this.activeSlider) {
        return;
      }

      this.activeSlider.next();
    },
    prev() {
      if (!this.activeSlider) {
        return;
      }

      this.activeSlider.prev();
    }
  }));
};
