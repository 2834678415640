import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('featuredReviews', () => ({
    activeSlide: window.innerWidth < 1024 ? 1 : 0,
    firstSildeIndex: window.innerWidth < 1024 ? 1 : 0,
    slider: null as null | BlazeSlider,

    async init() {
      const { default: BlazeSlider } = await import('blaze-slider');

      this.slider = new BlazeSlider(this.$refs.blazeSlider, {
        all: {
          loop: true,
        },
      })

      this.slider.onSlide(() => {
        const slideNumber = this.slider?.slides?.[this.firstSildeIndex]?.getAttribute('slide-number');
        if (slideNumber) {
          this.activeSlide = parseInt(slideNumber);
        }
      })
    },

    next() {
      this.slider?.next();
    },
    prev() {
      this.slider?.prev();
    },
    moveToSlide(e: any) {
      const clickedSlide = e.target.parentNode;

      if (this.slider?.slides?.length) {
        for (let i = 0; i < this.slider?.slides.length; i++) {
          if (this.slider.slides[i] == clickedSlide) {
            const count = i - this.firstSildeIndex;
            if (count > 0) {
              this.slider?.next(count);
            } else if (count < 0) {
              this.slider?.prev(Math.abs(count));
            }

            break;
          }
        }
      }
    }
  }));
};