import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('carousel', () => ({
    
    async init() {
      const { default: BlazeSlider } = await import("blaze-slider");

      new BlazeSlider(this.$refs.blazeSlider, {
        all: {
          loop: false,
          slidesToShow: 1.25,
        },
        '(min-width: 550px)': {
          slidesToShow: 2.25,
        },
        '(min-width: 1024px)': {
          slidesToShow: 4,
        }
      })
    }
  }));
};